<template>
  <div class="pdf">
    <pdf v-for="i in numPages" :key="i" :src="url" :page="i"></pdf>
    <!-- <pdf
      ref="pdf"
      :src="url"
      :page="pageNum"
      @progress="loadedRatio = $event"
      @page-loaded="pageLoaded($event)"
      @num-pages="pageTotalNum = $event"
      @error="pdfError($event)"
      @link-clicked="page = $event"
    >
    </pdf> -->
  </div>
</template>
<script>
import pdf from "vue-pdf";
export default {
  name: "Reader",
  components: {
    pdf,
  },
  props: {
    fileUrl: {
      type: String,
    },
  },
  data() {
    return {
      pageNum: 1,
      pageTotalNum: 1,
      // 加载进度
      loadedRatio: 0,
      curPageNum: 0,
      url: "",
      numPages: 1,
    };
  },
  watch: {
    fileUrl(nV) {
      this.getNumPages(nV);
    },
  },
  mounted: function () {
    if (this.fileUrl) this.getNumPages(this.fileUrl);
  },
  methods: {
    // 上一页函数，
    prePage() {
      var page = this.pageNum;
      page = page > 1 ? page - 1 : this.pageTotalNum;
      this.pageNum = page;
    },
    // 下一页函数
    nextPage() {
      var page = this.pageNum;
      page = page < this.pageTotalNum ? page + 1 : 1;
      this.pageNum = page;
    },
    // 页面顺时针翻转90度。
    clock() {
      this.pageRotate += 90;
    },
    // 页面逆时针翻转90度。
    counterClock() {
      this.pageRotate -= 90;
    },
    // 页面加载回调函数，其中e为当前页数
    pageLoaded(e) {
      this.curPageNum = e;
    },
    // 其他的一些回调函数。
    pdfError(error) {
      console.error(error);
    },
    getNumPages(url) {
      var loadingTask = pdf.createLoadingTask(url);
      loadingTask.promise
        .then((pdf) => {
          this.url = loadingTask;
          this.numPages = pdf.numPages;
        })
        .catch(() => {
          console.error("pdf加载失败");
        });
    },
  },
};
</script>
<style lang="less" scoped>
.pdf {
  width: 100%;
  overflow: auto;
}
</style>