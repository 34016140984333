<template>
  <div class="container">
    <v-reader :fileUrl="url" />
  </div>
</template>
<script>
import Reader from '@/components/reader'
export default {
  name: 'FileReader',
  components: {
    'v-reader': Reader
  },
  data() {
    return {
      url: ''
    }
  },
  created() {
    this.url = this.$route.query.url;
  }
}
</script>
<style lang="less" scoped>
.container {
  width: 1280px;
  margin: 0 auto;
}
</style>